import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import DBProgramsByRegion from '../images/charts/chart_database-programs-by-region_arabic.png';
import DBProgramsByType from '../images/charts/chart_database-programs-by-type_arabic.png';
import INNPartnerByRegion from '../images/charts/chart_deep-dive-programs-by-region_arabic.png';
import INNPartnerByType from '../images/charts/chart_deep-dive-programs-by-type_arabic.png';

const toggle = (
  showEnglish: boolean,
  setShowEnglish: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return (e: KeyboardEvent) => {
    // lowercase 'e'
    if (e.keyCode === 69) setShowEnglish(!showEnglish);
  };
};

const BlueprintArTemplate = () => {
  const [showEnglish, setShowEnglish] = useState(false);
  const toggleEnglish = toggle(showEnglish, setShowEnglish);
  useEffect(() => {
    document.addEventListener('keydown', toggleEnglish);
    return () => document.removeEventListener('keydown', toggleEnglish);
  });
  return (
    <>
      <div className="rtl" style={{ position: 'relative' }}>
        <article className={'module-featured'}>
          <h1>
            المنصّة العالمية لـ
            <br />
            The Incarceration Nations Network:
          </h1>
          <p>نظرة عامة</p>
          <figure>
            <img
              src="https://www.berlin.de/converjon/?ts=1418132797&width=540&height=270&url=https%3A%2F%2Fwww.berlin.de%2Fjustizvollzug%2F_assets%2Fjva-heidering%2Fdie-anstalt%2Fpforte.jpg"
              alt="Placeholder"
              width="1440"
              height="710"
            />
          </figure>
        </article>
        <article className={'s16'} id={'context'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>الحبس الجماعي كارثة عالمية.</h2>
          </header>
          <div className={'triple'}>
            <div>
              <p>
                يشكّل الاحتجاز بأعداد هائلة كارثةً عالميةً. فقد وصل عدد المساجين
                اليوم حول العالم إلى 11 مليون شخص تقريباً، وهذا الرقم في تزايد
                مستمر. فقد ازداد عدد السجناء في 78% من البلدان ما بين العامي
                2008 و2011، وبنسبة 20% تقريباً ما بين عامي 2000 و2016. ومن العام
                2000 حتّى العام 2015، ازداد عدد السجناء في منطقة أوقيانوسيا
                بنسبة تقارب الـ 60 %، فيما ازداد عدد السجناء في القارة الأميركية
                أكثر من 40% (14% في الولايات المتحدة، أكثر من 80% في دول أمريكا
                الوسطى، و145% في دول أمريكا الجنوبية). وقد تضاعف عدد السجناء
                المحكومين مؤبداً منذ العام 2000.
              </p>
            </div>
            <div>
              <p>
                لم يصدر بأكثر من 3.2 ملايين سجين من أصل هؤلاء الـ 11 مليون حكماً
                بعد – أي أنّهم قانونياً بريئين ينتظرون المحاكمة. وواحدٌ من كلّ
                خمسة من هؤلاء الـ11 مليون سجين يرتبط جرمه بالمخدرات، حتّى أنّ 83
                % منهم موقوفين للحيازة على المخدرات فقط. يظهر تقريراً صدر عن{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://justice.sdg16.plus/report"
                >
                  Task Force on Justice
                </a>
                ، أنّ 4.4 مليار شخص من حول العالم مستثنيين من الفرص المتاحة
                قانونياً، فيما يعاني 244 مليون شخص من الظلم الشديد.
              </p>
            </div>

            <div>
              <p>
                وبالرغم من تسميته الاحتجاز بأعداد هائلة، لا يطال هذا الاحتجاز
                جميع المجتمعات بطريقة متساوية. فتستهدف المجتمعات "المختلفة" بشكل
                شديد التفاوت في أنظمة العدالة الجنائية – سواء كانت مجتمعات
                الأميركيين الأفارقة أو اللاتينيين في الولايات المتحدة، أو الشعوب
                الأصلية في كندا، أستراليا ونيوزلندا، أو الغجر في أوروبا، أو
                الفقراء في بلدان الجنوب العالمي. ويعود ذلك إلى البيئة المحيطة
                بالوقائع التاريخية والحالية المبنية على المعايير الرأسمالية.
              </p>
            </div>
            <div>
              <figure>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </figure>
            </div>
            <div>
              <p>
                أمّا الأزمة التي تنتج عن ذلك، فلا تقتصر على مجال حقوق الإنسان
                فقط، بل تشكّل أيضاً تهديداً لسلم وسلامة المجتمع لأنّ السجون –
                التي تعتبر طريقةً غير مبتكرة وقديمة لتوليد السلامة العامة والحد
                من الجريمة – لا تساهم في بناء مجتمعات آمنة، كما لا تساهم في دعم
                السلام والعدالة؛ وكثير من البيانات تثبت ذلك. خلص تقرير
                <a
                  style={{ color: 'black' }}
                  href="https://penphilanthropy.org/files/Focus_Areas/Criminal_Justice_Reform/The_impacts_of_incarceration_on_crime_10.pdf"
                >
                  Open Philanthropy Project
                </a>{' '}
                الصادر عام 2017 والذي قام بدراسة 35 دراسة عالمية، أنّ الأحكام
                القاسية "قليلٌ ما تحدّ من الجريمة" وأنّ الوقت الذي يمضيه الفرد
                في السجن "يميل إلى أن يزيد من جرمية الفرد بعد الخروج من السجن" –
                بمعنى آخر، إنّ السجون تنتج الجرائم بدلاً من الحدّ منها. ووجدت
                دراسة أجرتها{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://Nature.com/articles/s41562-019-0604-8.epdf"
                >
                  جامعة كاليفورنيا في بيركلي
                </a>
                ، أنّ أحكام السجن لا تردع الناس عن ارتكاب جرائم العنف، والسجن لا
                يؤثر سوى بنسب غير ملحوظة في تخفيف مستوى جرائم العنف ككل.
              </p>
              <p>
                وهذا هو الدافع وراء تأسيس The Incarceration Nations Network وهي
                شبكة عالمية ومركز أبحاث تشجّع وتسوّق الجهود المبتكرة في إعادة
                تصور السجون والعدالة من حول العالم.
              </p>
            </div>
            <div>
              <figure className={'m35'}>
                <img
                  src="https://static.wixstatic.com/media/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.webp"
                  alt="Placeholder"
                  width="400"
                  height="175"
                />
              </figure>
              <p>
                وقد بنيت منصّة The Incarceration Nations Network هذه - وهي
                الأولى من نوعها على حد علمنا، للاستجابة لهذه الأزمة العالمية –
                معتمدةً على شغف حلّ الإشكاليات، سواء كانت واسعة أو صغيرة النطاق.
                وتتألف من قاعدة بيانات تجمع 800 برنامج عالمي تقريباً وتتوسّع
                بشكل أكثر تعمّقاً في 82 برنامجاً واعداً يعتمدون نشاطاتٍ
                وإشكالياتٍ مختلفة جمعناها ضمن فئات محددة، وذلك لعرض جهود إصلاح
                السجون وإعادة تصورها من حول العالم.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'premises'}>
          <header className={'text-center'}>
            {showEnglish && (
              <h2 style={{ color: 'black' }}>PREMISES OF THE INN PLATFORM</h2>
            )}
            <h2 style={{ color: 'black' }}>أسس المنصّة:</h2>
            <p style={{ color: '#7D7D7D' }}>
              ترتكز منصّة The Incarceration Nations Networkعلى ثلاثة أسس أوليّة
              عن العدالة:
            </p>
          </header>
          <div className={classnames('double', 'a')}>
            <div className={'m0'}>
              <ul>
                <li>
                  <p>
                    تشكّل طرق تحقيق العدالة غير المرتكزة على السجون، والكامنة في
                    مفهوم العدالة التصالحية ومقاربات أكثر إحالة وتوجيهاً،
                    أساليباً أكثر إنسانية وفعاليّة في بناء المجتمعات الآمنة
                    والعادلة مقارنةً مع السجون والاحتجاز. ويجب أن تشكلّ هذه
                    الطرق الطريق الأساسي إذا صح التعبير، نحو تحقيق العدالة،
                    بدلاً من أن تعد بدائلاً عن الاحتجاز. وفي إطار ذلك، يجب أن
                    نعيد صياغة سؤالنا الجوهري من "لماذا برامج إعادة التأهيل في
                    السجون فعالة للغاية؟" إلى سؤال يتخطّى فكرة السجون متسائلين
                    "ما الذي يبني مجتمعات آمنة؟ وما هي حاجات المتضررين وكيف يمكن
                    الاستجابة لها؟".
                  </p>
                </li>
                <li>
                  <p>
                    فيما يعدّ العالم بحاجة ماسة إلى تغييرات هيكلية وتصورات
                    مبتكرة واسعة الأفق للتوجه نحو العدالة، لم تختف الحاجة إلى
                    السعي نحو تحقيق إصلاحات عملية وتضافر الجهود للملايين الذين
                    هم على المحك في الوقت الراهن. فتخفيف الاكتظاظ في السجون
                    وتحسين ظروف الاحتجاز هما حقّ أساسي في إطار حقوق الإنسان
                    وسلامة المجتمع. وبالتالي، يمكن، ولو نتج عن ذلك تناقضاً،
                    السعي نحو عمليات إصلاحية مركزية في إنقاذ الملايين على المحك،
                    والعمل لتحقيق أساليب مختلفة لإلغاء السجون بما فيها الإصلاحات
                    في الوقت نفسه.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    عندما يتعلّق الأمر بسلامة المجتمعات، الوقاية هي الأهم.
                    وبالتالي، يجب أن يرتكز المجتمع على المساواة، والإنصاف،
                    والفرص الاقتصادية والخدمات الاجتماعية القوية. فكما تعلم حركة
                    إعادة استثمار العدالة Justice Reinvestment movement، لا يمكن
                    تحقيق العدالة الحقيقية في نقطة واحدة فقط، فهي واسعة النطاق
                    والمطال وتغير جميع أوجه المجتمع باسم السلام.
                  </p>
                </li>
              </ul>
              <figure>
                <img
                  src="https://www.score.gov.sg/images/default-source/default-album/chef-working-in-kitchen.jpg"
                  alt="Placeholder"
                  width="785"
                  height="280"
                />
              </figure>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'goals'}>
          <figure className={'background'}>
            <img
              src="https://mediaprocessor.websimages.com/www.corkalliancecentre.com/patricks%20hill%20view.jpg"
              alt="Placeholder"
              width="1440"
              height="800"
            />
          </figure>
          <header className={'text-center'}>
            {showEnglish && <h2>GOALS OF THE INN PLATFORM</h2>}
            <h2>أهداف المنصّة:</h2>
            <p>
              تتعدد أهداف منصّة The Incarceration Nations Networkوهي التالية:
            </p>
          </header>
          <div className={'triple'}>
            <div>
              <ul>
                <li>
                  بناء مخطط وخارطة طريق يقدّمان حلولاً واعدة لمشكلة السجون
                  العالمية.
                </li>
                <li>
                  إثبات أن مناقشات إصلاح السجون وإعادة تصورها يمكن أن يحدث
                  عالمياً ويكون عابراً للقارات، متنبّهين إلى خاصيات كلّ بلد
                  واستحالة اعتماد مقاربة واحدة عامة لجميع البلدان.
                </li>
                <li>
                  بناء تحالفات عابرة للحدود ودعم المنظمات التي تقوم بأعمال حيوية
                  لتحقيق العدالة، بخاصة المنظمات التي تؤدي هذا العمل وتخدم
                  المساجين في بيئات حكومية عقابية معادية.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  تطوير قاعدة بيانات واسعة ليكون كلّ من المنظّمات العالمية التي
                  تجري أعمالاً إصلاحية في السجون والأكاديميين والباحثين
                  والمبتكرين من حول العالم، متفقون على الهدف نفسه وعلى الصفحة
                  نفسها.
                </li>
                <li>
                  تحفيز شبكة عالمية من صانعي التغيير تسمح بنقل البرامج المتينة
                  القويّة حول العالم.
                </li>
                <li>
                  افتعال مناقشات عابرة للحدود عن الصعوبات والنجاحات الشائعة.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  جمع البيانات المبتكرة لجذب أشخاص جدد إلى إشكالية الاحتجاز
                  بأعداد هائلة العالمية، بخاصةٍ اللاعبين الأساسيين في هذا المجال
                  والممولين الجدد والمقترعين والإعلام.
                </li>
                <li>
                  توفير مصدر عمليّ للمنظمات العاملة في البلدان التي تحتجز
                  أجانباً في سجونها، والتي يمكنها الاستفادة من التعاون مع
                  منظّمات من الخارج.
                </li>
              </ul>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'audience'}>
          <header className={'text-center'}>
            {showEnglish && <h2>AUDIENCE FOR THE INN PLATFORM</h2>}
            <h2 style={{ color: 'black' }}>جمهور المنصّة:</h2>
            <p style={{ color: '#7D7D7D' }}>
              تهدف منصّة The Incarceration Nations Network العالمية الوصول إلى
              جمهور واسع يتألف من:
            </p>
          </header>
          <div className={'module-aside'}>
            <figure>
              <img
                style={{ maxHeight: '330px', objectFit: 'cover' }}
                src="https://www.crcnsw.org.au/wp-content/uploads/2016/10/highlight2.jpg"
                alt="Placeholder"
                width="865"
                height="330"
              />
            </figure>
            <div>
              <p>
                المؤسسات الحكومية والوزارات، المنظمات غير الحكومية العاملة في
                مجال العدالة، الإعلام، الأكاديميون والطلاب في مجالات مرتبطة
                بتحقيق العدالة، الممولون المحتملون من القطاع الخاص، المؤسسات
                الخيرية وغيرها من الممولين، الناشطون والمواطنون، السجناء و/أو
                السجناء السابقون "الذين لم يفصحوا بعد عن ذلك"، والذين يعانون من
                الوصمة السلبية والإقصاء الاجتماعي، عائلات وأقارب السجناء.
              </p>
            </div>
          </div>
        </article>
        <article className={'mn'} id={'methodology'}>
          <header className={'text-center'}>
            {showEnglish && (
              <h2>
                METHODOLOGY FOR DATA COLLECTION/ORGANIZATION OF THE INN PLATFORM
              </h2>
            )}
            <h2 style={{ color: 'black' }}>
              تجميع البيانات من جميع المنظّمات لمنصّة The incarceration nations
              network
            </h2>
          </header>
          <div className={'double a module-spaces tablet-compact'}>
            <div>
              <p>
                لوضع قاعدة البيانات، التي تحتوي على حوالي 800 برنامج عالمي،
                استعانت The Incarceration Nations Network بفريقها من المستشارين
                العالميين في مجال العدالة، بالإضافة إلى مستشارين أكاديميين
                وعاملين في جمعيات مختلفة. كما عملت على وضع آلية بحث عبر الانترنت
                تعتمد الكلمات المفتاح بالإضافة إلى اسم البلد (كـ"السجن"، "إصلاح
                السجون"، "العدالة التصالحية"، "الإحالة"، "البدائل عن السجن"،
                "المحاكمات/إحالة المذنب"). وتمّ رصد أيّ برنامج ذات موقع إلكتروني
                فعّال ومعلومات للتواصل. بحسب معلومات The Incarceration Nations
                Network، قاعدة البيانات هذه هي الوحيدة من نوعها وحجمها عالمياً،
                غير أنّها لا تدّعي الشمولية المطلقة. جرى البحث باللغتين
                الإنجليزية والإسبانية.
              </p>
              <p>
                في اختيار البرامج التي سيتمّ التعمق بها، لجأت The Incarceration
                Nations Network إلى مستشارين عالميين في مجال العدالة، طالبةً
                توصيات عن برامج قويّة ومميزة، كما استشارت عاملين في هذا المجال
                من أصحاب الخبرات الأكاديميين والناشطين في المنظمات غير الحكومية.
                وأعطيت الأولوية للبرامج التي تعطي الأشخاص المتأثرين مباشرةً
                دوراً مركزياً، لأنّ The Incarceration Nations Network تعتبر أنّ
                "من هم أقرب إلى المشكلة هم الأقرب إلى الحلّ"، بحسب قول
                JustLeadership USA.
              </p>
              <p>
                في تصنيف برامج قاعدة البيانات، اعتمدت The Incarceration Nations
                Network على المعلومات المتوفّرة للجميع. وقد صنّفت المنظّمات
                أصحاب البرامج المتعمق بها برامجها بنفسها، وغالباً ما تدير
                منظّمة/جمعيّة برامج متعددة، كلّ منها تذكر كبرنامج على حدة. تقدّم
                منظّمات كثيرة مزيجاً من الخدمات المباشرة وخدمات المناصرة،
                وغالباً ما لا تعتبرهما "برنامجين" مستقلين. في هذه الحالة، وضعنا
                هذا العمل المتآزر تحت أنواع برامج مستقلّة.
              </p>
            </div>
            <div>
              <p>
                أرسل إلى المنظمات المشاركة في منصة البرامج المتعمّق بها
                استطلاعاً من 50 سؤالاً، مستند الـPDF الروابط رقم 1-4 عن عملهم،
                وأساليبهم، واستراتيجياتهم للتغيير وغيرها. وتشمل هذه الاستطلاعات
                أسئلة مغلقة وأخرى غير مغلقة بهدف جمع بيانات كميّة ونوعية عن كلّ
                برنامج. كما أرسل استقصاءً للعملاء لـ 39 منظّمة، مستند الـ PDF
                الرابط رقم 5، لتوزيعه على 5 مشاركين في البرنامج بهدف جمع بيانات
                نوعيّة عن البرامج.
              </p>
              <ol>
                <li>
                  <a
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                    style={{ color: 'black' }}
                  >
                    INN_DirectServices_Questionaire_.pdf
                  </a>
                </li>
                <li>
                  <a
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/2bs7ebzwYpqUTW0e38aVz4/8b82c979473cd3023d7dc7edfe07a02b/INN_Alternatives_to_Incarceration_Questionaire_.pdf"
                    style={{ color: 'black' }}
                  >
                    INN_Alternatives_to_Incarceration_Questionaire_.pdf
                  </a>
                </li>
                <li>
                  <a
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                    style={{ color: 'black' }}
                  >
                    INN_DirectServices_Questionaire_.pdf
                  </a>
                </li>
                <li>
                  <a
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/5MOyuKg9Phsrh1yLhcub56/def96255a13b7ba334ff22bfa22bf77f/INN_DirectServices_Innovative_Alternatives_to_Traditional_Prisons_Questionaire_.pdf"
                    style={{ color: 'black' }}
                  >
                    Innovative_Alternatives_to_Traditional_Prisons_Questionaire_.pdf
                  </a>
                </li>
                <li>
                  <a
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/1HPZ9vmVLMG2nZrwsZu6X4/87c80904023e859a826f35a97e07cbeb/INN_GlobalAssessment_ClientSurvey.docx"
                    style={{ color: 'black' }}
                  >
                    INN_GlobalAssessment_ClientSurvey.docx
                  </a>
                </li>
              </ol>
              <figure className={'mn'}>
                <img
                  src="https://www.genepi.fr/wp-content/uploads/2018/03/Re%C2%A6%C3%BCflexion-et-formation.jpg"
                  alt="Placeholder"
                  width="620"
                  height="555"
                />
              </figure>
              <p>
                زار المدير التنفيذي لـ The Incarceration Nations Network،
                الدكتور باز درايسنجر، البرامج الـ52 على منصّة البرامج المتعمّق
                بها، بما فيها برنامجاً على الأقلّ في كلّ منطقة جغرافية. وأجرى
                مقابلات مع الناشطين الأساسين من ممثلي المنظمات والعملاء.
              </p>
              <p>
                طلب من برامج كثيرة المشاركة في منصّة البرامج المتعمق بها، غير
                أنّها كانت غير قادرة على ذلك لأسباب مختلفة. وشعرت The
                Incarceration Nations Network بأنّ بعض من هذه البرامج مهمّة
                للغاية ولا يمكن عدم ذكرها. لذلك، تركت بعض الصفحات لعرض رسالتها
                ومقاربتها والتغطية الإعلامية لها، من دون البيانات الأخر تعمقاً
                التي تعذّر جمعها في تلك الأحيان.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'}>
          <div className={classnames('double', 'a', 'tablet-compact')}>
            <div>
              <p className={classnames('strong', 'm40')}>
                في اختيار البرامج لمنصّة البرامج المتعمق بها، جرى التركيز على
                توازن عوامل كثيرة للحرص على:
              </p>
              <hr />
              <ul>
                <li>جمع برامج من مناطق جغرافية مختلفة.</li>
                <li>
                  وجود برامج متنوعة واسعة على المستوى الوطني، وبرامج محليّة أصغر
                  نطاقاً.
                </li>
                <li>
                  توفر مزيج من البرامج الكبيرة الموجّهة نحو تغيير السياسات
                  والأنظمة، وبرامج الخدمات المباشرة المركّزة على الفرد "تنظّف
                  الفوضى" وتنقذ الأرواح في الآونة الحالية.
                </li>
                <li>
                  ضمّ برامج كبيرة وفعالة منذ وقت بعيد، وبرامج أصغر وأكثر حداثة
                  لم يتمّ إثبات نتائجها (بعد) غير أنّ نماذجها واعدة ومبتكرة ومن
                  المفيد وجودها على منصّة عالمية.
                </li>
              </ul>
              <p>
                <span className="strong">ملاحظة: </span>يغيب عن هذه القائمة
                البرامج الوقائية – بما فيها فرص الخدمات الاجتماعية، والتدخلات
                المجتمعيّة (بخاصة تلك التي تطال الشباب)، والتنمية الاقتصادية –
                ولكنّ ذلك يتخطّى نطاق عمل The Incarceration Nations Network في
                الوقت الحالي.
              </p>
              <p>
                لحظت The Incarceration Nations Network أيضاً أعمالاً ركزت على
                فئات متأثرة مباشرةً مثل:
              </p>
              <ul>
                <li>الجماعات الفائض تمثيلها في نظام العدالة الجنائية.</li>
                <li>النساء في نظام العدالة.</li>
                <li>المحتجزون في انتظار المحاكمة</li>
                <li>الشباب/الأحداث</li>
                <li>عائلات المساجين</li>
                <li>
                  جماعات مثلي الجنس، ومزدوجي التوجه الجنسي والمتحولين جنسيًا
                  والمتحيرين LGBTQ في السجون.
                </li>
              </ul>
            </div>
            <div>
              <p className={classnames('strong', 'm40')}>
                شكّل تحديد فئات المنصّة الخطوة الأولى في تحديد النقاط المشتركة
                بين البرامج وإنشاء نوع من مخطط أو خارطة طريق، وهذه الفئات هي
                التالية:
              </p>
              <hr />
              <ul>
                <li>البدائل عن السجن</li>
                <li>المناصرة والعمل على تغيير السياسات</li>
                <li>
                  الخدمات المباشرة للمتأثرين بالنظام السجني (المساجين والمساجين
                  السابقين وعائلاتهم).
                </li>
              </ul>
              <hr />

              <p>
                <span className="strong">ملاحظة: </span>يبقى تعريف "النجاح"
                عندما يتعلّق الأمر بإصلاحات السجون أمر معقّد يعجّ بالتظليل.
                لطالما اعتبر المقياس نسبة العودة إلى السجن، ولكنّه كما يلحظ
                العلماء والممارسون إنّ هذه الأرقام مظللة. (فتسجّل كلّ من
                البرازيل والسلفادور، على سبيل المثال، نسب أقلّ فيما يتعلّق
                بالعودة إلى السجن بالمقارنة مع تشيلي، لكنّ ذلك لا يرمز بالضرورة
                إلى نجاح النظام السجني في هذه البلدان – يمكن أن يعود بكلّ بساطة
                إلى الأحكام القصيرة الأمد في تشيلي التي تتيح للناس الدخول
                والخروج من النظام السجني بنسب أكثر). انطلاقاً من هنا، سألت The
                Incarceration Nations Network المنظّمات عن مفهومها للنجاح
                (مثلاً: ما الذي يجعل عملكم غير ضروري؟ وما هي رؤيتكم لتغيير طويل
                الأمد؟") وسعت إلى جمع البيانات الكمية والنوعية عن البرامج. تتوفر
                مزيد من المعلومات عن هذه المسألة وعن الممارسات القائمة على
                الأدلة في قسم النصائح الجوهريّة.
              </p>
              <p>
                <span className="strong">ملاحظة: </span>من أجل سهول الوصول إلى
                المعلومات – وهو مبدأ تعتمده The Incarceration Nations Network في
                جميع أعمالها – تستخدم هذه المنصّة مصطلحات شائعة تجد The
                Incarceration Nations Network أنّها تطرح إشكاليات. فكما ذكر
                أعلاه، "البدائل عن السجن" تضع السجن في قلب نظام العدالة.
                ومصطلحات على سبيل مثل "إعادة التأهيل" و"إعادة الاندماج في
                المجتمع" توحي بأنّ الناس في السجون قد كانوا مندمجين أساساً، فيما
                هو العكس تماماً. على المستوى العالمي، يأتي السجناء من أكثر
                المجتمعات تهميشاً وفقراً، وبالتالي تكون أيّ فرصة تتوفر لهم في
                السجن هي الفرصة الأولى التي تتوفر لهم، وليس فرصة أخرى.
              </p>
              <p>
                <span className="strong">ملاحظة: </span>لا يعتبر قسم البرامج
                المتعمّق بها تقييماً للبرامج باعتماد أداة قياس عالمية، بل تمّ
                اختيار البرامج لأنّها قيّمة وقد تشكل نماذج برامج مستقبلية. ويعرض
                هذا القسم تحدّيات ونجاحات شائعة تتشاركها البرامج من حول العالم
                كما يسلّط الضوء على أعمال قوية ناجحة في سياقات مختلفة. تتوفر
                مزيد من المناقشات عن هذه المسألة في قسم النصائح الجوهريّة.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-featured'} id={'common'}>
          {showEnglish && <h1>COMMON STRUGGLES & STRENGTHS</h1>}
          <h1>التحدّيات الشائعة ونقاط القوّة</h1>
          <figure>
            <img
              src="https://i.vimeocdn.com/video/693991407.webp?mw=1440&mh=710&q=70"
              alt="Placeholder"
              width="1440"
              height="710"
            />{' '}
            <i className={'icon-pluses'}></i>
          </figure>
        </article>
        {/* <div>
          <PageMenu menuItems={BLUEPRINT_SUB_MENU} />
        </div> */}
        <article>
          <header className={'text-center'}>
            {showEnglish && (
              <h2 style={{ color: 'black' }}>
                THE MASTER DATABASE (783 PROGRAMS)
              </h2>
            )}
            <h2 style={{ color: 'black' }}>
              قاعدة البيانات الأساسية (783 برنامجاً):
            </h2>
          </header>
          <div>
            <h3 style={{ textAlign: 'center' }}>الرسم البياني رقم 1</h3>
            <p style={{ textAlign: 'center' }}>
              قاعدة البيانات الأساسية بحسب المنطقة والنوع
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={DBProgramsByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={DBProgramsByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>الصورة رقم 1</h3>
            <p style={{ textAlign: 'center' }}>
              قاعدة بيانات البرامج بحسب المنطقة والنوع
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="http://www.incarcerationnationsnetwork.com/wp-content/uploads/2020/02/table-1.png"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>الرسم البياني رقم 2</h3>
            <p style={{ textAlign: 'center' }}>
              البرامج المتعمق بها بحسب المنطقة والنوع{' '}
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={INNPartnerByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={INNPartnerByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>الصورة رقم 2</h3>
            <p style={{ textAlign: 'center' }}>
              البرامج المتعمق بها بحسب المنطقة والنوع
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="http://www.incarcerationnationsnetwork.com/wp-content/uploads/2020/02/table-2.png"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
        </article>
        <article className={classnames('module-wide', 'overlay-b')}>
          <div id={'alternatives'}>
            <header className={'text-center'}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>
                  Alternatives to incarceration
                </h2>
              )}
              <h2 style={{ color: 'black' }}>البدائل عن السجن:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  على المستوى العالمي، هناك شحّ في برامج البدائل عن السجن. وقد
                  وجدت The Incarceration Nations Network برامج أكثر تقدّم خدمات
                  مباشرة من بدائل عن الاحتجاز، فمن بين جميع البرامج في قاعدة
                  البيانات في وقت تقييم البيانات، شكّلت نسبة برامج البدائل عن
                  السجن 14% فقط (11 برنامجاً) بالمقارنة مع 60% للخدمات المباشرة
                  و26 للمناصرة وتغيير السياسات). ومن البرامج المتعمّق بها، قامت
                  63% من برامج البدائل عن السجن بتسمية أهدافها المباشرة وقصيرة
                  المدى بـ"ممارسات عدالة تصالحية تجريبية"، مقترحة حاجة كبيرة
                  لتنمية ودعم المشاريع الجديدة في هذا الحقل.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  يعتبر حوالي ثلث برامج البدائل عن السجن المتعمق بها (31.1%) أنّ
                  المشكلة الأساسية التي يحاولون معالجتها هي "التمييز / الإفراط
                  في تمثيل مجموعات معينة (على أساس الثقافة أو العرق) في السجون".
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>

                <p>
                  عندما تتوفر برامج البدائل عن السجن (بخاصة برامج العدالة
                  التصالحية)، تكون مركّزة غالباً على الأحداث فقط. ويبدو أن هناك
                  توتّر في توفير هذه الفرص للراشدين – الذين، هم أيضاً، كانوا
                  يوماً أحداثاً يواجهون نظاماً جارحاً.
                </p>
                <figure>
                  <img
                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                    src="https://cdhep.org.br/wp-content/uploads/2019/04/Curso_V%C3%ADtima_Ofensor_Comunidade_1.jpg"
                    alt="Placeholder"
                    width="415"
                    height="200"
                  />
                </figure>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة المشتركة:
                </h3>
                <p>
                  تتواجد برامج البدائل عن السجن منذ وقت طويل يعادل 18 عاماً –
                  وهو أطول برنامج من الفئات الثلاثة الكبرى التي تمّ التعمق بها.
                  وصلت برامج البدائل عن السجن إلى نطاق واسع، وفيها أكبر عدد من
                  المشاركين من بين فئات البرامج الثلاثة. تجمع برامج البدائل عن
                  السجن أكبر عدد من الموظفين ما بين البرامج المتعمّق بها، غير
                  أنّها تعتمد أيضاً وبشكل كبير على المتطوعين الذين يشكّلون ما
                  يعادل 30% من الأفراد الذي يعملون في المنظمات. ويرجح أن يكون
                  على الأقل أحد موظّفي برامج البدائل عن السجن سجيناً أو سجيناً
                  سابقاً (30% من البرامج).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة المشتركة:
                </h3>
                <p>
                  تعدّ الرؤية بعيدة المدى للتغيير لنصف برامج البدائل عن السجن:
                  "الأحكام البديلة / العدالة التصالحية هي الاستجابة الأساسية
                  للجرائم والأحكام".
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة المشتركة:
                </h3>
                <p>
                  تعدّ كلفة برامج البدائل عن السجن أقلّ بكثير من برامج الخدمات
                  المباشرة (التي تحتسب إمّا كإجمالي كلفة البرنامج أو الكلفة لكل
                  المستفيد). تتراوح تكلفة هذه البرامج السنوية (من 4000 دولار إلى
                  15 مليون دولار)، لكن برنامج البدائل عن السجن الأكثر كلفةً هو
                  أقلّ كلفة بثلاث مرّات من برنامج الخدمات المباشرة.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'restorative'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>Restorative justice</h2>
              )}
              <h2>العدالة التصالحية:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">ملاحظة: </span> تشمل برامج البدائل عن
                  السجن جوانب من العدالة التصالحية والاجتهاد العلاجي (في
                  نيوزيلندا، على سبيل المثال، يجبر قانون الأحكام الصادر في عام
                  2002 القضاء على الأخذ بعين الاعتبار العملية التصالحية في
                  أحكامهم). غير أنّ The Incarceration Nations Network صنفت
                  البرامج التي تذكر بكلّ وضوح أنّها تعنى بالعدالة التصالحية ضمن
                  هذه الفئة.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  في كثير من بلدان العالم، لا تزال العدالة التصالحية مفهوم غير
                  شائع وغامض للمجتمع – ويواجه أزمة علاقات عامة. فقد اضطر أحد
                  برامج الولايات المتحدة وقف تسمية عمله "community
                  conferencing"، بالعربية "تنظيم المؤتمرات للمجتمع"، التي ترمز
                  إلى عمليّة جمع كلّ الأطراف المعنية بحادثة أو جرم ما، لأنّه تمّ
                  الخلط بها وبين الشركات التي تؤجّر قاعات للمؤتمرات. كما تحدّثوا
                  عن غياب المفهوم في المدارس والأنظمة العدلية في المنطقة، رغم
                  توفّره منذ أكثر من عقدين.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  يعتبر المنسّقون المتمرسون، الذين يشاركون في ما تسميه
                  Restorative Response Baltimore في الولايات المتحدة، "نموذج
                  النمو المستمر"، أساسيين لنجاح أي عمل عدالة تصالحية. بالمقارنة
                  مع التدريبات الميسرة واسعة النطاق التي غالباً ما تميّع العملية
                  أو المفاهيم، وتدرب شرائح من الناس قد لا يكونوا مناسبين تمامًا
                  لتأدية دور المنسّقين المعقّد.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تمّ اختيار برامج العدالة التصالحية لمنصّة البرامج المتعمق بها،
                  لأنّها تتخطّى وتحيل الناس عن نظام العدالة الجنائية التقليدي
                  بدلاً من أن تشكّل ملحقاً له (مثلما تفعل البرامج التي تعمل مع
                  المساجين). وتشعر The Incarceration Nations Network بحماسة تجاه
                  هذه البرامج بسبب مقربتها للعدالة غير المعتمدة على السجون.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'diversion'}>
            <header className={classnames('text-center')}>
              {showEnglish && <h2 style={{ color: 'black' }}>Diversion</h2>}
              <h2>الإحالة</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  تشمل برامج إحالة كثيرة إحالة الناس إلى برامج أخرى غير السجن
                  (مراكز المعالجة من المخدرات/الكحول، وبرامج الإرشاد، وبرامج
                  المشورة بشأن الأحزان وغيرها). لذلك، عرّف 43 % من برامج البدائل
                  عن السجن سرّ نجاحهم الأساسي "بالتعاون مع شركاء خارجيين". غير
                  أنّ منظّمات كثيرة تشير إلى أنّ هذه البرامج تفتقر إلى الموارد
                  وغيرها من الأساسيات، بخاصة عندما يتعلّق الأمر بالوعي الثقافي
                  حيال المجموعات الفائض تمثيلها. تستجيب Aboriginal Legal
                  Services في كندا إلى هذه المشكلة بمقاربة قيّمة: أن تصبح محطّة
                  واحدة، وتبني برامج داخلية من وإلى السكان الأصليين.
                </p>
                <img
                  className={'desktop-only'}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/bxp41924s.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  خطط الإحالة الفردية والتي تعتمد مقاربة طويلة المدى أساسيّة
                  للغاية – فليس هناك من خطّة واحدة تناسب الجميع. في البرامج
                  القويّة، تتعاون وكالات مختلقة مطّلعة على خاصيات كلّ شخص وتعمل
                  مع عائلاتهم والمرشدين الاجتماعيين وأنظمة التعليم والصحة. في
                  نيوزيلندا على سبيل المثال، تتعاون Family Group Conferences و
                  Youth Court و Rangatahi لوضع خطط عمل فردية؛ فيما تعتزّ Red
                  Hook Community Justice Center في الولايات المتحدة بكونها
                  "تركّز على الفرد" ولا تصنّف العملاء بحسب نوع الجريمة. في
                  هولندا، تتابع جمعية Halt حوالي 16 ألف شاب في العام – ثلث
                  القاصرين الذين يتم اعتقالهم في البلد – وتجد، رغم ذلك، القدرة
                  على بناء خطط إحالة فردية لكلّ منهم، في عمليّة تتطلب ما يعادل
                  ثلاثة أشهر من الوقت. يتطوّر هذا البرنامج باستمرار وينتقل نحو
                  العمل الوقائي بالتوجه نحو المدارس، وإقامة التجارب مع فئات
                  عمرية مختلفة، واستخدام الألعاب والواقع الافتراضي في التعليم.
                  وفيما يتعلّق بنتائجها، 75% من الأفراد الذين تقوم الجمعية
                  بإحالتهم، لا يرتكبون انتهاكات أخرى.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تظهر محاكم السكان الأصليين في نيوزلندا وأستراليا، بالإضافة إلى
                  Red Hook Community Justice Center في الولايات المتحدة، أنّ
                  هناك طرق بسيطة لجعل السياق القانوني أكثر إنسانية ومناسب
                  ثقافياً لتمثيل ما يسميه مؤسسو Koori Court في أستراليا
                  "التقاليد بدلاً من القانون" المتمثل بـ: غياب الرسميات المفرطة،
                  والسماح للمتهم بإبداء رأي وتأدية دور أساسي في العملية،
                  والتركيز على المجتمع من خلال الطقوس، والغناء ولمس الأيدي
                  والأوجه والتواجد المركزي لكبار السنّ المحترمين، والتحدث باللغة
                  الإنجليزية البسيطة وليس اللغة القانونية، وإزالة الحواجز
                  الملموسة المتوفرة في القوانين الغربية (قوانين البيض)، وتمثيل
                  ذلك في أماكن الجلوس (فلا يجلس القاضي على منصّة عالية مثلاً)،
                  وإعطاء العملية وقتاً طويلاً بدلاً من الإسراع في اتخاذ القرار،
                  والتركيز على تصميم داخل المحكمة: توفر الضوء الطبيعي والجداريات
                  وتجنب الخشب الداكن، بالإضافة إلى استخدام صيغ الاحترام (مثل
                  "رجاءً" و"شكراً") على اللافتات.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'mediation'}>
            <header className={classnames('text-center')}>
              {showEnglish && <h2 style={{ color: 'black' }}>MEDIATION</h2>}
              <h2 style={{ color: 'black' }}>الوساطة</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  تعدّ الأنظمة القانونية في العام مكتظّة، فالمحامون (أو المحامون
                  للأشخاص الذين ليس لديهم إمكانيات للدفع) قليلون جدّاً –
                  والاستجابة الأفضل لهذا الموضوع تكمن في مقاربة العدالة من
                  الأدنى إلى الأعلى وليس العكس: أي إحالة الناس قبل دخولهم إلى
                  المنظومة القضائية. ففي كثير من الأحيان، لا يكون هناك حاجة
                  للمحامين للانتقال نحو القرار. وبالتالي، يكون الناشطون في
                  التمكين القانوني والسلم المجتمعي أساسيين، تماماً مثل الذين
                  يعملون في Madaripur Mediation Program في بنغلاديش وبرنامج Red
                  Hook Community Justice Center لصنع السلام في الولايات المتحدة
                  حيث يتدرب العاملون فيه في محمية نافاجو. ويمكن القول أنّ تطوير
                  مبادرات مدعومة من الحكومة لصنع السلام كالذي قامت به جمعية Red
                  Hook Community Justice Center هو مجال خصب جاهز للاستثمار به.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  في مجتمع محمية نافاجو، يتوفر نظام{' '}
                  <span style={{ fontStyle: 'italic' }}>k’e</span> للقرابة الذي
                  يقوم على قيم الاحترام واللطف والتعاون والود والمعاملة بالمثل.
                  يسود هذا النظام قاعدة للتصرف مع الآخر "كأن ليس لديهم أيّ أحد
                  أو أي أقارب"، ويمكن لأنظمة الوساطة للاستفادة من هذه المجتمعات
                  وقيم العائلة لديها لإحلال السلام.
                </p>
              </div>
            </div>
          </div>

          <hr />
          <div id={'advocacy'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>POLICY/ADVOCACY</h2>
              )}
              <h2>السياسات/ المناصرة</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  في جميع أنحاء العالم ، تدعو المنظمات الهادفة إلى إصلاح السجون
                  إلى كثير من الأمور نفسها، وهي بشكل عام: 1. إصلاح السياسات
                  المتعلقة بالجرائم البسيطة و/أو الحرب الوحشية على المخدرات؛ 2.
                  الإصلاحات المتعلّقة بالأحكام؛ 3. تحسين ظروف السجن؛ 4. سياسة
                  الكفالة ما قبل المحاكمة. وتعتبر هذه الأخيرة ذات أهمية خاصة على
                  المستوى العالمي، حيث تم تسميتها من قِبل 50٪ من البرامج المتعمق
                  بها من بين أهم نقاط السياسات التي تسعى للتأثير عليها.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/8109-org.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تستخدم منظمات كثيرة الأساليب نفسها لمعالجة القضايا المذكورة
                  أعلاه وهي التالية: 1. التفاعل مع واضعي السياسات؛ 2. التقاضي
                  الاستراتيجي؛ 3. تغيير الرأي العام عبر الحملات الإعلامية أو/و
                  الفنون؛ 4. إنتاج الأبحاث والتقارير. وعندما يتعلق الأمر بأسباب
                  هذه المقاربة، فإن 65٪ من البرامج تعتبر أنّه "إذا وصل صانعو
                  القرار إلى معلومات أدقّ، فسوف يتخذون قرارات أفضل" ، في حين 45٪
                  من البرامج تعتير أنّه "إذا تحلّى صانعو القرار بتعاطف أكبر مع
                  الإنسان وتأثيرات خياراتهم ، فسوق يتخذون قرارات أفضل ".
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  من المسائل بالغة الأهميّة العابرة للحدود، والمناسبة للتقاضي
                  الاستراتيجي نذكر: إصلاحات الكفالة، المعوقات عن التعليم، إيجاد
                  الوظائف والسكن للسجناء السابقين (المعروف في الولايات المتحدة
                  كحركة “Ban the Box” (، مسألة العطلة القضائية حيث يذهب القضاة
                  في عطلة أو يغيبون فيتوقف النظام فتكتظ السجون. العدالة، حسبما
                  تقول The Incarceration Nations Network لا تأخذ عطلة أبداً.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'directServices'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>DIRECT SERVICES</h2>
              )}
              <h2>الخدمات المباشرة</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  حددت 20% من برامج الخدمات المباشرة ما بين البرامج المتعمق بها
                  الـ81 "غياب برامج إعادة التأهيل" كالمشكلة أو الحاجة الأساسية
                  التي يعالجها برنامجهم. غير أنّه في قائمة البيانات 60% من
                  البرامج العالمية تقع في خانة الخدمات المباشرة (انظر الرسم
                  البياني رقم 1). ومن بين هذه البرامج، أغلب البرامج تقع في خانة
                  "إعادة الإدماج في المجتمع" (153 برامجاً أي 20% من جميع
                  البرامج)، وخانة "خدمات إعادة تأهيل أخرى" (79 برنامجاً أي 10%
                  من جميع البرامج). أمّا 14% فقط من البرامج (111)، تقع ضمن خانة
                  البدائل عن السجن. من هنا، يمكن الاستنتاج أنّ غالباً ما تطغى
                  وتسيطر الجهود لحلّ المشاكل التي يسببها الاحتجاز على موارد
                  الجهود بعيدة المدى لإلغاء الاحتجاز ومشاكله. ورغم ذلك، لا تزال
                  جهود الخدمات المباشرة غير كافية، إذ أنّ المنظّمات ترى نقصاً في
                  هذا الإطار.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  غالباً ما تتداخل عناصر الخدمات المباشرة ببعضها بعضاً، فيكون
                  شبه مستحيل متابعة حاجة أحد المساجين من دون مواجهة في الوقت
                  نفسه حاجات أخرى له. لهذا السبب، من الضروري جمع فريق شامل متعدد
                  الاختصاصات. فعلى سبيل المثال، تجمع جمعية عدل ورحمة AJEM من
                  لبنان محامين وعامليين اجتماعيين وأخصائيين في علم النفس للقيام
                  بما يسمونه "مقاربة متعددة الأبعاد للوقاية وإعادة التأهيل
                  وإعادة الإدماج في المجتمع". من جهتها، تعتمد منظّمة The Red
                  Hook Community Justice Center مقاربة متعددة الأبعاد في عدة
                  أنظمة قضائية، فتتعاون مع مكتب المدعي العام والمحاكم بالإضافة
                  إلى مؤمّني المسكن والتعليم والصحة والمساعدة القانونية، في
                  القطاع العام أو مع المنظّمات غير الحكومية.
                </p>
              </div>
            </div>
            <hr />
            <div>
              <header className={classnames('text-center')}>
                {showEnglish && (
                  <h2 style={{ color: 'black' }}>LEGAL/BAIL ASSISTANCE</h2>
                )}
                <h2>المساعدة القانونية</h2>
              </header>
              <div className={classnames('double', 'a', 'hr')} id={'legal'}>
                <div>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    التحدي الشائع:
                  </h3>
                  <p>
                    في كثير من البلدان، بخاصةٍ بلدان الجنوب العالمي، يعمل
                    القانون كأداة استعمارية جديدة، يدير حياة الناس من دون
                    معرفتهم طريقة عمله. فقد فرضت الجذور الاستعمارية في جميع
                    أنحاء العالم، قوانين كثيرة في مجتمعات تكون غير ملائمة لها.
                    والمثال الأكبر على ذلك صورة قاض أفريقي في بلد مثل غانا يرتدي
                    ثوبًا رسميًا وشعراً مستعارًا أبيضًا قديمًا في قاعة محكمة
                    حارة للغاية بعيدًا عن إنجلترا. فكما تذكّرنا منظّمة MyJustice
                    في ميانمار، إنّ كلمة "القانون" ترمز إلى "الكذب" في إحدى
                    اللغات التيبتية الصينية المتداولة في البلد. وتعمل منظّمات
                    كثير مثل CHREAA في مالاوي و MyJustice في ميانمار و Advocaid
                    في سيراليون، على جعل القانون في متناول المجتمع من خلال
                    المسرحيات والفنون، وذلك للوصول إلى عامة الشعب، بخاصة في
                    المناطق الريفية.
                  </p>
                  <img
                    style={{
                      minHeight: '295px',
                      minWidth: '100%',
                      objectFit: 'cover',
                    }}
                    src="https://advocaidsl.org/wp-content/uploads/2018/12/IMG_0774.jpg"
                    alt="Placeholder"
                    width="360"
                    height="295"
                  />
                </div>
                <div>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    نقطة القوة:
                  </h3>
                  <p>
                    على المستوى العالمي، تستخدم المنظمات مقاربات مشتركة كثيرة
                    لمساعدة وتخفيف عدد السجناء، لا سيما المحتجزين المؤتين. وتشمل
                    هذه المقاربات: 1. تقديم المساعدة القانونية في أسرع وقت ممكن
                    (في مراكز الشرطة في الهند عبر منظّمة CHRI مثلاً) بالإضافة
                    إلى أنواع مختلفة من الخدمات الشاملة (Advocaid في سيراليون، و
                    Brooklyn Defender Services في الولايات المتحدة)؛ 2. وضع
                    تقييمات للمخاطر الحساسة ثقافياً لإبعاد الأشخاص عن الاحتجاز
                    بانتظار المحاكمة (JJAI في المكسيك)؛ 3. استخدام المحاكم
                    المتنقلة والتدريب القانوني في السجون لإخلاء الأشخاص من
                    الاحتجاز (برنامج Justice for All في غانا)؛ 4. جمع أموال
                    الكفالة والمناصرة لإصلاح سياسة الكفالة (The Bail Project في
                    الولايات المتحدة الأمريكية)؛ 5. تمكين المحامين والمساعدين
                    القانونيين لإتمام القضايا المتراكمة (African Prisons Project
                    في كينيا وأوغندا)؛ 6. تقديم المساعدة القانونية ومهارات
                    التعليم والتمكين القانوني (CHREAA في مالاوي).
                  </p>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    نقطة القوة:
                  </h3>
                  <p>
                    تتبع بعض المنظمات مقاربة شاملة للمساعدة القانونية على غرار
                    Brooklyn Defender Services في الولايات المتحدة و Advocaid في
                    سيراليون، وتقدّم خدمات اجتماعية متعددة لعملائها إذ تدرك أنّ
                    الأفراد العالقين في نظام العدالة الجنائية غالباً ما تتخطى
                    المساعدة القانونية.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div id={'reentry'}>
            <header className={classnames('text-center')}>
              {showEnglish && <h2 style={{ color: 'black' }}>REENTRY</h2>}
              <h2>إعادة الاندماج في المجتمع:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  يشكّل برنامج "إعادة الاندماج في المجتمع" الفئة الأكبر في قاعدة
                  بيانات وقائمة البرامج المتعمّق بها، منوهاً بحجم الأزمة التي
                  تشكّلها هذه المسألة وحجم الموارد المستنفذة لحلّها. وقد أعلنت
                  34% من برامج الخدمات المباشرة المتعمق بها أنّ هدفها هو "تحسين
                  ظروف، وموارد، وآفاق إعادة الإدماج في المجتمع بعد الخروج من
                  السجن" (فيما 27% منها عبّرت عما هو عله الهدف وهو "تحسين وضع
                  المساجين ومهاراتهم خلال فترة احتجازهم).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  رغم أنّ معوقات إيجاد وظائف ومسكن وتعليم للسجناء السابقين حقيقة
                  عالمية، ، غير أن الجهود المبذولة لإزالة هذه الحواجز - سواء
                  القانونية (عن طريق التقاضي الاستراتيجي وغيرها من أعمال
                  المناصرة) أو الثقافية (عن طريق تغيير الرأي العام عن السجناء
                  السابقين وقبولهم في المجتمع) - نادرة في كل مكان تقريبًا، إلا
                  في الولايات المتحدة ، حيث يعرفون باسم " Ban the Box " أي
                  "إلغاء الخانة" (في إشارة إلى الخانة في الطلبات حيث يطرح السؤال
                  عن السجل الجنائي). وحتى في بلدان مثل هولندا، حيث لا توجد
                  معوقات وحواجز كبيرة، يظنّ السجناء السابقون بأنّ هذه الحواجز
                  موجودة ويترددون لانتهاز الفرص. من جهة أخرى تبقى الحواجز غير
                  الرسمية، مثل الوصمة والعار، سائدة ويصعب تحديدها.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  تفتقر الحكومات إلى وحدات قوية لإعادة الإدماج في المجتمع،
                  باستثناء حكومة سنغافورة، وذلك على المستويين المحلي والوطني.
                  كما تفتقر الحكومات إلى قاعدة بيانات للوظائف المتوفرة لإعادة
                  الإدماج في المجتمع (كتلك المتوفرة في سنغافورة، والتي تضم 5520
                  صاحب عمل). ويمكن القول إنّ الحاجة لهذا المجال كبيرة، وكذلك هي
                  قدرته على النموّ وتحقيق التقدم.
                </p>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تعتمد برامج إعادة الإدماج في المجتمع الناجحة ركيزتين: تكمن
                  الأولى في تتابع الخدمات التي تبدأ عندما يحتجز الشخص وتكمل بعد
                  خروجه (تطلق سنغافورة على هذه الخدمة "العناية الدقيقة" وجميع
                  برامجها ملتزمة بها). أمّا الركيزة الثانية، فتكمن في مقاربة
                  شاملة لتحديات إعادة الإدماج العالميّة: إيجاد المسكن والوظائف
                  وتأمن الدعم المعنوي والدعم اللاحق للحادثة. تميّز هذه العوامل
                  فئة "إعادة الإدماج في المجتمع" عن فئات "الخدمات المباشرة"
                  الأخرى، والتي تقتصر على الخدمات داخل السجن فقط. من جهتها، تشعر
                  The Incarceration Nations Network بأن كلّ متابعة تبدأ في السجن
                  يجب أن تكمل في خارج السجن، بالعمل مع السجناء السابقين بعد
                  خروجهم.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  يعتبر العامل المركزي الأكثر أهميّة في أيّ برنامج إعادة إدماج
                  في المجتمع: الناس. فلا تطبيق ولا خوارزمية يمكن أن تستبدل
                  إنساناً يعمل على دعم إنسان آخر يعاود الاندماج في المجتمع.
                  تقدّم منظّمة SCORE في سنغافورة فريقاً متنقلاً يزور ويتابع
                  العملاء فيما يعتادون على حياتهم العملية (ما يساهم بنسبة احتفاظ
                  بالوظيفة للسجناء السابقين تساوي 81%). في حين تتبع منظّمة
                  Community Restorative Centre NSW في أستراليا "نموذج إدارة
                  حالات مكثف"، "غير رسمي وجامد" كما يصفه فريق عملها، ويشمل
                  التواصل المستمر مع السجناء السابقين، والاطمئنان عنهم عبر
                  الهاتف خلال العمل. من جهته، يقدّم برنامج الإسكان ما بعد السجن
                  بإدارة Exodus في هولندا، مرشدين يعيشون مع المستفيدين متوفرين
                  على مدار الساعة لتقديم الدعم وبناء الصداقة. وقد اعتبر 28.4% من
                  البرامج المتعمق بها أنّ "التفاني وشغف العاملين" هم مفتاح نجاح
                  هذه البرامج. وخلال مناقشات The Incarceration Nations Network
                  مع العملاء الخارجين من السجن، سلط الضوء مجدداً ومجدداً على
                  أهمية الدعم الفردي.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  يعتبر الكثيرون أنّ عملية إعادة الإدماج في المجتمع طويلة جدّاً
                  ومليئة بالتقلبات. وذلك يعني أن الدعم المقدّم يجب أن يمتد إلى
                  ما بعد عدة أشهر أو حتى سنة واحدة منذ بدء هذه العملية. تتحدث
                  Exodus في هولندا، عن نوع من برامج الرعاية اللاحقة للذين
                  انتقلوا من مساكنها ولكنهم ما زالوا يريدون التحدث مع المرشدين
                  والاستفادة من الدعم والفرص.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  يمثل إيجاد المسكن تحديا عالميا للسجناء السابقين الخارجين من
                  السجن، لذلك يعد الابتكار في توفير فرص الإسكان أمراً ضرورياً،
                  بخاصة في المدن ذات الأسواق السكنية المرتفعة الثمن. تدير منظّمة
                  Pension Skejby في الدنمارك، مساحةً تجمع الطلاب والسجناء
                  السابقين حيث يتشاركون المساحات المشتركة ويبنون المجتمع سوياً.
                  ويقوم مشروع The Homecoming Project في الولايات المتحدة، بجمع
                  الأشخاص الذين لديهم مساحة في منازلهم مع السجناء السابقين
                  العائدين من السجن.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'education'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>EDUCATION BEHIND BARS</h2>
              )}
              <h2>برامج التعليم في السجون:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  في الدراسات الاستقصائية للعملاء من ثلاثة برامج تعليمية على
                  مستوى الجامعة في السجون وفي ثلاثة بلدان مختلفة في ثلاث قارات
                  مختلفة (كينيا والولايات المتحدة وإيطاليا)، أشاد الطلاب
                  المسجونون ببرامجهم بمصطلحات متشابهة. وأكدوا أن التعليم العالي
                  يمكّنهم، ويجعلهم يرون المستقبل بشكل أكثر إيجابية ويزيد من
                  إيمانهم بقدراتهم الخاصة ("أكدّ تعليمي المستمر ثقتي بقدراتي،
                  وإيماني بأن هناك حياة بعد الحبس" حسبما قال الطالب من NJ-Step
                  من الولايات المتحدة؛ ووصف الطالب في Articolo3 من إيطاليا
                  التعليم الجامعي في السجن، قائلاً أنّها أمنت له "الثقة في نفسي،
                  وإمكانية الحصول على مستقبل، ورؤية حياتي بألوان بدلاً من الأسود
                  والأبيض". وتحدث طالب من African Prisons Project في أوغندا
                  وكينيا، عن "تغيير موقفي تجاه الحياة وتصوراتي أيضًا، وتمكيني من
                  خلال اكتساب المعرفة ... وتعزيز الاحترام لي وللآخرين وتعزيز
                  كرامتي"؛ وأشار طالب آخر من المنظمة إلى أن "البرنامج قد أثبت
                  بالفعل أن السجناء لديهم حلول لمشاكلهم، كل ما يحتاجون إليه هو
                  الدعم "). وأشار كلّ من طلاب African Prisons Project في أوغندا
                  وكينيا و NJ Step في الولايات المتحدة بأن البرامج التعليمية
                  تجلب مزيدًا من الاستقرار إلى السجن أيضًا.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تعتبر The Incarceration Nations Network أنّ للجامعات دوراً
                  أساسياً في بناء المجتمعات الآمنة، وأنّ الطرق المبتكرة للشراكات
                  بين الجامعات والسجون مليئة بالفرص. تحتوي الجامعات على قوى
                  عاملة تتلقى تدريبات – كالأساتذة الطلاب والمرشدين الاجتماعيين
                  وغيرهم - الذين يمكنهم الاستفادة من النفاذ إلى السجناء، والذين
                  بدورهم يستفيدون من التشبيك مع الشباب وقادة المستقبل الذين
                  يبنون مستقبلهم المهني. بشكل عام، تشكّل الجامعات مؤسسات ثابتة
                  من دون توجهات سياسية واضحة – وذلك أمر نادر في البلدان التي
                  تواجه عدم استقرار سياسي واقتصادي. لذلك، تعد مفيدة للغاية في
                  بناء برامج في السجن والمحافظة عليها على المدى البعيد.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  برامج التعليم القوية في السجون أساسية جدّاً؛ فمستويات التعليم
                  ترتبط دائماً بسلامة المجتمع، كما تشير الأبحاث والمنطق. ومع
                  ذلك، تغيب هذه البرامج حتّى عن الأماكن حيث هي عادةً منتشرة
                  كأوروبا مثلاً.{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://antoniocasella.eu/nume/Hawley_UE_education_may13.pdf"
                  >
                    وأشار إحصاء صدر عام 2013 عن التعليم والتدريب
                  </a>{' '}
                  في السجون في أوروبا، أنّه في أغلبية البلدان الأوروبية، نسبة
                  المشاركة في التعليم والتدريبات في السجون أدني من 25%. وتشمل
                  معوقات المشاركة التجارب السيئة مع التعليم، وفكرة أنّ هناك
                  تشجيع أكبر للمشاركة في الأعمال في السجن من المشاركة في برامج
                  التعليم، بالإضافة إلى عدد الأماكن المحدودة في هذه البرامج.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تعتبر جميع مستويات التعليم خلف القضبان أساسيّة، لا سيما فيما
                  يتعلق بمحو الأمية والحساب. لكن برامج التعليم العالي لا ينبغي
                  التغاضي عنها. لا يقتصر الأمر على رفع المستوى فيما يتعلق بالرأي
                  العام حول إمكانيات السجناء، بل هو استثمار في القيادة
                  المستقبلية ، وطريقًا إلى فرصة حقيقية بسبب هذا المستوى العالي.
                  استحداث فرص عمل منخفضة المستوى أو العمل بأجر، لا يمكنهم معالجة
                  التمييز، غير أنّ الاستثمار في تعليم جيد والفرص القوية والرؤية
                  القيادة يمكن أن يشكّلوا وسيلةً للتغيير.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://images.squarespace-cdn.com/content/57cd909c197aea06e48be99b/1537440132261-JEW860FOZYJPQ5GB8NNL/IMG_2054.JPG?format=750w&content-type=image%2Fjpeg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
            </div>
          </div>
          <hr />
          <div id={'jobDevelopment'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>JOB DEVELOPMENT BEHIND BARS</h2>
              )}
              <h2>تطوير المهارات في السجون:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  <a
                    href="https://prisonpolicy.org/reports/outofwork.html"
                    style={{ color: 'black' }}
                  >
                    خلصت دراسة أجريت في الولايات المتحدة في العام 2018
                  </a>
                  ، أن نسبة البطالة بين السجناء السابقين تتخطّي 27%، ما يتخطى
                  نسبة البطالة ي الولايات المتحدة في أيّ وقت ويعد أكبر بخمسة
                  أضعاف نسبة البطالة العامة. وبالرغم من عدم توفّر دراسات مشابهو
                  عالمياً، غير أنّ شركاء The Incarceration Nations Network
                  يسجّلون دائماً غياب الوظائف للسجناء السابقين كمشكلة عالميّة.
                </p>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://static.wixstatic.com/media/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.webp"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تعد النماذج التعاونية (مثل Coostafe و Humanitas360 في
                  البرازيل) مهمّة بشكل خاص ، لأنها تمنح المساجين مصلحة أسهماً في
                  أعمالهم الخاصة وتملكهم لها ، ما يؤثر على حياتهم بعد الإفراج
                  عنهم، و يحثهم على مقاومة نموذج العمل في السجن (والعمل
                  بالعبودية) الذي له تاريخ عالمي قمعي طويل.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تظهر استطلاعات العملاء أن العديد من البرامج (على غرار Nafisika
                  في كينيا) تنوي تحقيق شيءٍ واحد يكمن في: إعطاء الشخص مهاراتٍ
                  وظيفيةٍ. ولكنها في النهاية المطاف، تقدّم له أشياء كثيرة تتجاوز
                  نطاق المهارات المهنية: من تعزيز الثقة والمشاركة في المجتمع،
                  وزيادة الاهتمام بالتعليم إلى تحسين العلاقات الشخصية وتوليد
                  التعاطف.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تهدف البرامج المبتكرة إلى تدريب عملائها على الوظائف المطلوبة،
                  مثل زراعة البساتين من خلال منظّمة Hlumelilisa في جنوب إفريقيا،
                  والبرمجة كما في منظمة The Last Mile في الولايات المتحدة،
                  وريادة الأعمال مع Nafisika في كينيا. تستخدم هذه البرامج أيضاً،
                  مثل البرامج القوية لإعادة الإدماج في المجتمع، متابعة في مرحلة
                  تلو الأخرى، فيساعدون عملائهم في إيجاد الوظائف بعد الخروج من
                  السجن.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'staffTraining'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>PRISON STAFF TRAINING</h2>
              )}
              <h2>تدريب موظفي السجون:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  تثير فكرة أنّ تدريب موظفي السجون ثاني أقلّ فئة من الخدمات
                  المباشرة القلق، مع 14 برنامجاً فقط يركز على تدريب السجون. فكما
                  هي المدارس قوية بأساتذتها، كذلك تعدّ أنظمة العدالة والسجون
                  قويّة بموظفيها. يتزايد الاهتمام العالمي بظروف الاحتجاز وأوضاع
                  السجون، بخاصةٍ هندستها، غير أنّ ذلك لا يطال تدريب موظّفي
                  السجون، والتدريبات وتوظيف الأشخاص ذات الفكر التقدمي – وتبدو
                  هذه التغييرات وكأنها شكلية فقط.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تجذب الثقافة التي تقدّر موظّفي السجون مرشحين قويين للمهنة.
                  تعترف مدرسة التدريب في النروج و Unlocked Graduates في بريطانيا
                  بذلك، ويدفعون رواتب تنافسية مؤمّنين بيئة عملية وثقافة مشجعة.
                  كما يعملون نحو تغيير النظرة إلى هذه الوظيفة في سياق التوظيفات
                  الجامعية، بالإضافة إلى إنشاء برنامج زمالة لموظفيها.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'rehabServices'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>
                  OTHER REHABILITATIVE SERVICES BEHIND BARS
                </h2>
              )}
              <h2>الخدمات التأهيلية الأخرى في السجون:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <img
                  style={{
                    minHeight: '275px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="http://www.insightprisonproject.org/uploads/1/1/6/0/11602085/1345745274.jpg"
                  alt="Placeholder"
                  width="360"
                  height="275"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  تكشف استطلاعات العملاء من المنظمات في أربع دول مختلفة ممتدة
                  على ثلاث قارات (لبنان، والسلفادور، وبلجيكا، وبريطانيا) عن
                  فوائد مشتركة لجهود إعادة التأهيل المتنوعة في السجون سواء من
                  خلال المسرح أو حل النزاعات أو الكتابة الإبداعية. وقد شدد أغلب
                  المشاركين على الثقة والروابط المجتمعية التي تبنيها هذه البرامج
                  ونذكر منها: "المجتمع، بناء علاقة مع الآخرين. لقد أصبحت أكثر
                  إنسانية. لقد بدأت بمساعدة الآخرين دون توقع أي شيء في المقابل
                  "(مشارك من جمعية عدل ورحمة AJEM ، لبنان) ؛ "يتيح لنا البرنامج
                  التفكير سويًا كمجموعة، حيث يشارك كلّ منا رأيه. وذلك يجعلنا
                  نتعايش كأصدقاء ومؤلفين "( مشارك من جمعية Contextos ،
                  السلفادور) ؛ "كنت أشعر أنني بمفردي، ولم يكن لدي أحد أتحدّث معه
                  إلى أن بدأت البرنامج... الآن أعلم أنه يمكنني مقابلة أشخاص
                  والتحدث معهم. ولا أشعر بالوحدة "( مشارك من جمعية De Rode
                  Antraciet ، بلجيكا) ؛ "لقد شاهدت مجموعة من السجناء لا تربطهم
                  أي علاقة مباشرة يتقربون من بعضهم ويصبحون بمثابة العائلة
                  ويظهرون الاهتمام والتعاطف تجاه بعضهم بعضاً، و من الجميل أن
                  نشهد على ذلك" (مشارك من جمعية LEAP ، بريطانيا).
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'health'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>HEALTH & SAFETY BEHIND BARS</h2>
              )}
              <h2>السلامة والصحة في السجون:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  1% فقط من البرامج تقع في هذه الخانة في قاعدة البيانات، رغم
                  الأعداد المثيرة للقلق من المصابين بالسيدا وأوبئة السلّ في
                  السجون، بخاصةٍ في أفريقيا وجنوب شرق آسيا.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  لا يمكن المبالغة في تقدير أثر الحرب على المخدرات على السجناء
                  في جميع أنحاء العالم. ففي حين يكتسب تشريع الماريجوانا والتعامل
                  مع تعاطي المخدرات كأزمة صحيّة بدلاً من أزمة عدالة جنائية - بما
                  في ذلك مقاربات الحد من الضرر – زخماً عالمياً ولو بطيئاً، إلا
                  أن هذه القضية تحتاج إلى مزيد من الزخم من أجل إحداث أثر ملموس.
                  يعدّ هذا المجال أرضاً خصبة للنموّ وقد يؤدّي إلى تقليل عدد
                  السجناء المحتجزين بنسب كبيرة.
                </p>
              </div>
              <div>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://justdetention.org/wp-content/uploads/2015/10/joke_cropped_hi_res-1024x741.jpg"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
            </div>
          </div>
          <hr />
          <div id={'innovative'}>
            <header className={classnames('text-center')}>
              {showEnglish && (
                <h2 style={{ color: 'black' }}>
                  INNOVATIVE ALTERNATIVES TO TRADITIONAL PRISONS
                </h2>
              )}
              <h2>البدائل المبتكرة للسجون التقليدية:</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">ملاحظة: </span>في قاعدة البيانات،
                  تتواجد أعلى نسبة من هذه البرامج في أوروبا.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  التحدي الشائع:
                </h3>
                <p>
                  نماذج "السجون النموذجية" التي تتمثل بمبنى أو جناحاً أو اثنين
                  في بلد ما تشكّل استثناءً تقدمياً ما بين السجون المتوفرة غير
                  التقدمية، وهي ترمز إلى ما يمكن ويجب أن يحصل. غير أنّها خطرة
                  كونها تبقى ذريعة للتغيير، تظهر من حين إلى آخر وتعيق بذلك
                  التغييرات الهيكلية في النظام. يعدّ Amsterdam West’s Small
                  Scale Detention Center الذي انطلق كنموذج احتجاز تجريبي مفتوح
                  للشباب الذين يواجهون الاحتجاز، نموذجاً لكيفية "أثر مركز صغير
                  على بلد كبير"، كما ذكر مؤسسه. ومن المخطط أن تؤسس مراكز مشابهة
                  في كلّ أنحاء البلد انطلاقاً من هذا العام، مؤدية إلى تسكير
                  مراكز احتجاز كثيرة للأحداث وتغييرات هيكلية كبيرة.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  نقطة القوة:
                </h3>
                <p>
                  في استجابة للسؤال: "ماذا يميز منظّمتكم عن السجون التقليدية؟"،
                  أجابت ثلاثة من البرامج الخمسة بـ" مبادئ أو فلسفة العقاب"، فيما
                  أجاب برنامجان من البرامج الخمسة أنّ ما يميزهم هو "التعاون بين
                  المجتمعات المحليّة والأفراد" و"مستويات أعلى من الحرية".
                </p>
                <img
                  style={{
                    minHeight: '375px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://pulitzercenter.org/sites/default/files/styles/node_images_768x510/public/11-17-14/unusual-prison-11.jpg?itok=BrnW17ps"
                  alt="Placeholder"
                  width="360"
                  height="375"
                />
              </div>
            </div>
          </div>
        </article>
        <div id={'bigPicture'}>
          <article className={classnames('module-featured', 'box')}>
            {showEnglish && <h2>Big-picture takeaways from the platform</h2>}
            <h2> النصائح الجوهريّة التي يمكن تعلّمها</h2>
            <p>
              <span
                className={classnames('strong', 'text-uppercase')}
                style={{ fontStyle: 'italic' }}
              >
                Kua tawhiti ke to haerenga mai, kia kore e haere tonu; He tino
                nui rawa o mahi, kia kore e mahi nui tonu. <br /> <br /> لقد
                وصلت إلى حدّ بعيد، فمن المؤسف أن تتوقف الآن. لقد فعلت الكثير،
                فمن المؤسف ألا تفعل المزيد
              </span>{' '}
              <br /> – السيد جيمس هنار (Ngati Hine, 1989).
            </p>
            <figure className={'overlay-d'}>
              <img
                src="https://placehold.it/1440x710"
                alt="Placeholder"
                width="1440"
                height="710"
              />
            </figure>
          </article>
          <article className={'mn'}>
            <div className={classnames('double', 'a', 'tablet-compact')}>
              <div className={'m0'}>
                <ul>
                  <p>
                    <span className="strong">ملاحظة: </span> استوحيت النصائح
                    والاستنتاجات الجوهرية على المنصة، كما حالة الأعمال في السجون
                    من البيانات التي تم جمعها من الاستبيانات، ومن زيارات الدكتور
                    دريسينجر للمنظمات والمقابلات الرسمية وغير الرسمية معها ومع
                    العملاء. يمكن العثور على جداول ومخططات بيانية للإجابات على
                    الاستبيانات المتعلقة بالبرامج المتعمق بها{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      هنا
                    </a>
                    ، ويمكن الاطلاع على قائمة المراجع ذات الصلة{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/4qE4rtxCNWUOUdpGizTuoG/a029e92dc23a6c5ad28ba03ed67bc796/GlobalAssessment_Bibilio.docx"
                    >
                      هنا
                    </a>
                    .
                  </p>
                  <li>
                    <span className="strong">
                      التفكير الإبداعي الخارج عن المألوف:{' '}
                    </span>
                    ليس فقط ممكناً أن تكون عالميًاً ومحلياً في الوقت نفسه، بل
                    إنّه مفيد كذلك. فعندما يخرج شخصاً من المألوف للتفكير بحلولٍ
                    للمشاكل، يفكر تلقائياً بما يمكن تحقيقه ويذهب إلى أبعد مما هي
                    عليه الأمور. غالباً ما يتوقف مناصرو العدالة الأكثر تقدماً
                    على ما هي عليه الأمور بدلاً من أن يفكروا بما يكمن أن تكون
                    عليه، ما يجب أن تكون عليه. حتّى الآن، تضمّن مسار عملية السجن
                    العالمية تصدير الولايات المتحدة لنموذجها للسجون، الذي يعتبر
                    تجربة، إلى العالم، أولاً في القرن التاسع عشر عبر الاستعمار
                    ومن ثم في القرن العشرين عبر العولمة. يمكن تغيير ذلك بإحداث
                    ثورة من الأفكار، فتتشارك مقاربات الولايات المتحدة وغير
                    الولايات المتحدة التقدمية، وتتم عمليّة التعلم في اتجاهات
                    مختلفة.
                  </li>
                  <li>
                    <span className="strong">نقل البرامج الواعدة: </span>
                    بدأ ذلك بالحصول على المستوى العالمي. فقد نقل نموذج سجن
                    باستوي النروجي في جمهورية التشيك مثلا، كما نقل نموذج الوساطة
                    في مارداريبور، بنغلاديش في ملاوي. غير أنّ هذه العملية ليس
                    دقيقة، فليس هناك من عدالة منقولة، لذلك يكون التحدّث هنا عن
                    نقل الفكرة وليس التقليد. ويجب أن تلحظ البرامج الخصائص
                    الثقافية لكل بلد، رغ الاعتراف بالخصائص العالمة للفقر
                    والعنصرية والظلم في خضم الأنظمة السجنية. وهنا يجدر الاطلاع
                    على نموذج حقّ الامتياز الاجتماعي{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://theguardian.com/social-enterprise-network/2012/nov/06/social-franchising-how-what-benefits"
                    >
                      على الرابط التالي
                    </a>
                  </li>
                  <li>
                    <span className="strong">أكثر من مجرد كلام فارغ: </span>
                    لقد اعتمدت الحكومات في جميع أنحاء العالم مصطلح "إدارة
                    الإصلاحيات" بدلا "إدارة السجون"، مركزين على تسليط الضوء على
                    تغيير المصطلحات هذا - لكنه في كثير من الأحيان شكلّ ذلك كلام
                    فارغ. فلا تزال الحكومات تستثمر القليل في "العمليات
                    الاصلاحية" وتضع السجون في أسفل الميزانية. ونظرًا لوجود نقص
                    في دعم الرأي العام للبرامج المتعلقة بالسجون، والخطاب العقابي
                    السائد – الموجه إلى معالجة الجرائم بصرامة بدلاً من معالجتها
                    بذكاء – لا تواجه الحكومات ضغوطًا لإنفاق المزيد على السجون
                    الإصلاحية، فتركز بدلاً من ذلك على القضايا الأكثر شعبية التي
                    تعود إليها بنتائج فورية (وبالتالي تفوز لهم بإعادة الانتخاب).
                    وبالتالي، يتوجب على الحكومات أن "تملي" (لعدم وجود مصطلح
                    أفضل)، العدالة الذكية للمجتمع، وليس العكس. غير أنّ ذلك ليس
                    ما يحصل عالمياً.
                  </li>
                  <li>
                    <span className="strong">نقص في الموارد والاستدامة: </span>
                    تدمر نتائج هذا السيناريو السلبي مجال العدالة. فمن بين
                    البرامج المتعمق بها، شكّل نقص التمويل المشكلة السائدة سواء
                    بشكل عام أو في السنة (69% يواجهون هذه المشكلة).بالعمل
                    بميزانيات صغيرة، تضطر المنظّمات للاعتماد على العمل التطوعي،
                    غير المستدام. يعيق أيضاً نقص التمويل المنظمات من التفكير في
                    نطاق واسع، إذ أنّ غياب التمويل يجعلهم يصبون تركيزهم على
                    كيفية الاستمرار في الوقت الحالي. بالتالي، يعيق ذلك قدرة
                    المنظمة على أن تكون جريئة ومبتكرة، كما قدرتها على انتقاد
                    نفسها. كذلك، ينتج عن ذلك غياب التضامن والوعي عن أعمال منظمة
                    أخرى في المجال نفسه في بلادهم، ويعزز هذا الواقع التنافس على
                    مصادر التمويل المحدودة المتوفرة.
                  </li>
                  <li>
                    <span className="strong">تغيير الخطاب: </span>
                    من أجل حلّ أزمة التمويل، تظهر ضرورة تغيير خطابات الرأي العام
                    عن السجون والسجناء، من خطابات عن الانتقام إلى خطابات عن
                    الإصلاح. عندما يطّلع الرأي العام بأنّ السجون لا تيني مجتمعات
                    أكثر أماناً، وأنّ أغلبية الناس في السجون بحاجة إلى مساعدة
                    وليس عقاب – وحينها يصبح الحديث عن السجون بمثابة الحديث عن
                    قضية، ليس موضوعاً محرماً عقابياً كما هي الحال في جميع أنحاء
                    العالم - حينها، سيطالب الشعب بالتغيير. على السياسيين إذا،
                    الانتباه والالتزام بأقوالهم، كما على الممولين أن يصبحوا أكثر
                    قبولاً لدعم الأعمال المتعلقة بالسجون. لقد بدأ هذا التغيير في
                    الخطاب في الولايات المتحدة، فمنذ أكثر من عقد، كان موضوع
                    إصلاحات السجون غير محبّذ أمّا الآن فأكثر المشاهير شهرةً
                    تتحدّث عنه. وهنا نسلّط الضوء أيضا على دور الفنون – من
                    الأفلام إلى التحف والكتب – الأساسي في تغيير الرأي العام
                    وبناء التعاطف (والأمثلة على ذلك الفيلم الوثائقي 13th المرشح
                    للأوسكار، وكتاب <i>The New Jim Crow</i> المتصدر للمبيعات.
                    أعرب جميع شركاء The Incarceration Nations Network عن تمنيهم
                    بأن تتضمن الميزانيات ما يسمح لهم باستثمار المزيد في التواصل
                    والإعلام. كما أعربوا عن إحباطهم فيما يتعلّق بالتغطيات
                    الإعلامية للجرائم وقضايا السجون، التي تزرع الخوف والرغبة
                    العقابية لدى الرأي العام، ما يعيق عملهم بشدّة. تعدّ قوة
                    الرأي العام احدى المسببات لحدود المقاربات المعتمدة على
                    الأدلة، ففيما قد تعلم الحكومات بالبيانات وما يمكن أن "ينجح"،
                    لا تقدم أحياناً على الخطوة إذا كان الرأي العام معارضاً لها
                    بشدّة. على المستوى العالمي، تصبح البرامج القوية بنادق في
                    اللعبة السياسية، على حساب سلامة المجتمع والسلم والعدالة.
                  </li>
                  <li>
                    <span className="strong">
                      إعادة كتابة الخطاب المجتمعي:{' '}
                    </span>
                    يعتبر التغيير الخطابي وسرد القصص عاملاً مغيّراً في ثقة
                    المجتمعات واستثمارها بنفسها. فحينما يتقبّل المجتمع هويةٍ مثل
                    "حيّ فقير مليء بالجرائم"، يعتاد إلى هذا النوع من الأحاديث
                    ويستثمر بها. لكّنه عندما يجد المجتمع أنّه ممكّنٌ يشتهر
                    بالسلام والعدالة، يعمل جاهداً للحفاظ على هذه الهوية
                    الإيجابية. يكشف عمل JustReinvest في أستراليا الكثير عن هذا
                    الواقع، فمن خلال عملهم أعاد مجتمع يشتهر بأنّه يعج بالجرائم
                    في أستراليا سرد قصتّه، فبات المجتمع الآمن بدلاً من المجتمع
                    "الخطر"، وبات المجتمع من تلقاء نفس يمثّل خطابه الجديد.
                    فخطابنا عن هويتنا له قوّة كبيرة علينا.
                  </li>
                  <li>
                    <span className="strong">تمويل الحكومات: </span>
                    يجب أن تهدف البرامج المبتكرة إلى التنظيم، ففي نهاية المطاف،
                    يعد بناء مجتمعات عادلة وتمويل البرامج والهيكليات التي تحقق
                    ذلك، من مهم الحكومات. غير أنّ البيانات التي جمعتها The
                    Incarceration Nations Network في البرامج المتعمق بها، تظهر
                    بأنّ هذا التنظيم يحصل بشكل متفاوت من حول العالم. فأكثر من
                    نصف البرامج المتعمق بها (54% من مجموعها) تجمع التمويل من
                    مصادر غير حكومية، غير أنّ الخدمات المباشرة (57%) والبدائل عن
                    السجن (63%) لا تزال بغالبيتها ممولة من الحكومات{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (انظر التفاصيل هنا)
                    </a>
                    . وحتّى بين البرامج الممولة بغالبها من الحكومات، أقل من نصف
                    التمويل يكون من مخصصات الميزانية، فيما يكون الباقي من المنح
                    الحكوميّة. يعتمد التمويل الحكومي للبرامج المتعلّقة بالسجون
                    على المنطقة. يعادل تقريباً التمويل من المصادر الحكومية تلك
                    من المصادر غير الحكومية في أمريكا الشمالية. أمّا التمويل في
                    أوروبا وأوقيانوسيا، فأغلبه من الحكومة (أكثر من 70%)، فيما 70
                    – 100% من البرامج في آسيا وأفريقيا وأمريكا اللاتينية ممولة
                    من مصادر غير حكومية (الصورة رقم 3). وعندما يتوفر التمويل
                    الحكومي، أعربت حكومات كثيرة عن إحباطها بالشروط القاسية
                    المتعلقة بالأرقام والنتائج، التي بحسب قولهم، تعيق القيام
                    بأعمال عالية الجودة لتحقيق أعمال بأعداد كبيرة.
                  </li>
                  <li>
                    <span className="strong">إيجاد مصادر تمويل مبتكرة: </span>
                    يقوم الممولون العالميون بتوفير الفئة الأكبر من التمويل غير
                    الحكومي الوارد على منصّة البرامج المتعمق بها، ويوفّر القطاع
                    الخاص أقلّ نسبة من هذا التمويل. ذكرت منظّمتان فقط مصادر
                    التمويل المبتكرة كالاستثمار ونماذج المشاريع الاجتماعية
                    كمصادر تمويل مبتكرة لها. نظراً إلى الأهميّة المتزايدة لأنظمة
                    التمويل هذه المستدامة والمغيّرة للنظام، يجب أن يكون العمل
                    بها في مجال العدالة أكثر انتشاراً ويجب أن يتمّ الترويج له في
                    ورش العمل والاجتماعات الديناميكية المفتوحة.
                  </li>

                  <li>
                    <span className="strong">التعاون: </span>
                    ذكرت 43.2% من البرامج المتعمق بها "التعاون مع شركاء
                    (خارجيين)"، كإحدى العناصر الأساسية للنجاح. تتضمن قائمة هؤلاء
                    الشركاء السياسيين المحليين والقضاة وموظفي الحكومة والمنظّمات
                    المجتمعية{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (الرسم البياني رقم 3)
                    </a>
                    . إلا أنّه وبحسب ما ذكر أعلاه، لا يتعاون العاملون في مجال
                    العدالة حول العالم بين بعضهم. ويجب على الحكومات أن تدعم
                    الاجتماعات والطاولات المستديرة الجامعة لعاملي قطاعات متعددة
                    مشتركة بهذا العمل، من أجل بناء جهات موحدة على غرار شبكة CARE
                    السنغافورية، التي تضمن التضامن فيما بينها.
                  </li>

                  <li>
                    <span className="strong">التحديات الأخرى: </span>
                    ذكرت البرامج المتعمق بها تحدّيات مشتركة أخرى مهمّة وهي: غياب
                    المساحة والبنية التحتية للنشاطات (من بين البدائل المبتكرة
                    للسجون التقليدية)، غياب الدعم الحكومي والسياسي (للبدائل عن
                    السجن)، وعدم الاستقرار السياسي أو تغيّر الطبقة السياسية
                    (لبرامج المناصرة وتغيير السياسات).
                  </li>
                  <li>
                    <span className="strong">
                      ابتكارات بسيطة ذات وقع كبير:{' '}
                    </span>
                    يتطلّب عمل العدالة ابتكارات جذرية. ولكن في الوقت نفسه، لا
                    يتطلّب خفض اكتظاظ السجون ودعم السجناء أفكار جديدة خلاقة
                    جدّاً، بل الالتزام والاستثمار باتباع مقاربات بسيطة ذات وقع
                    كبير – على غرار المحاكم المتنقلة، وبرامج الإحالة وإيجاد
                    الوظائف أثناء تأدية مدّة السجن وليس بعدها، والدعم المكثف بعد
                    الخروج من السجن وغيرها. في بلدان كثيرة، تعمل البرامج
                    الحكومية لإصلاحات السجون نظرياً وليس فعلياً، فرغم توفر
                    الخطاب المناسب والنموذج المناسب، إلا أنّه لا ينفّذ – بخاصةٍ
                    بسبب نقص الموارد والجهود المناسبة.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <span className="strong">
                      التركيز على العناية الفردية:{' '}
                    </span>
                    أفضل أنواع العدالة هي تلك الفردية – وهذا ما تسميه JustSpeak
                    في نيوزيلندا "مصممة خصيصاً للفرد" – لهذا السبب، كلما كان
                    النطاق أصغر، كلما كانت النتائج أفضل إذ أنّها تكون أكثر
                    فرديّة وتسمح لباقة من المقاربات المناسبة ثقافياً. بالرغم من
                    أهميّة توسيع المشاريع لأحداث تغييرات هيكلية، إلا أنّها إذا
                    افتقدت للموارد الأساسية لإحقاق العدالة ودعم الناس وإذا حصلت
                    قبل أوانها وقبل أن يحقق البرنامج نجاحات قد تسبب الكوارث.
                    وبالتالي، هناك فائدة كبيرة لتعدد المقاربات المصممة خصيصاً
                    للأفراد، بخاصةٍ أنّها تسمح بالتجارب والأفكار المبتكرة. ومن
                    الجهود المستحقة ذكرها في هذا المجال، المشروع الأوروبي
                    Rescaled الذي يطالب بأماكن احتجاز أصغر حجماً، "تمهد الطريق
                    نحو إعادة الاندماج في المجتمع، وتسمح بمقاربة أكثر فردية،
                    وتقلل من البيروقراطية، وتزيد الأمان وتؤمن عدد أكبر من الفرص
                    التي تتيح للمساجين بتحمل مسؤولية أفعالهم والتفاعل مع
                    المجتمع،" بحسب قولهم. عندما يتعلّق الأمر بالسجون وإعادة
                    الاندماج في المجتمع، التركيز على العناية الفردية ليس بأمر
                    سيء، بل هو أفضل إذ يسمح بمقاربة أكثر شخصيّة.
                  </li>
                  <li>
                    <span className="strong">
                      اعتماد مقاربة شخصية وليس عيادية
                    </span>
                    تتجنب المنظمات القوية المقاربات العيادية ولغة مقدمي الخدمات
                    وتفاعلهم مقابل التفاعل الإنساني الطبيعي وبناء العلاقات
                    المتينة. نتيجة ذلك، تعدّ بيوت إعادة الإدماج في المجتمع
                    Exodus في هولند، منازلاً لـ"مشاركين" وليس "عملاء"، كما
                    يتضمنون متطوعين وليس مقدمي خدمات؛ ولا تقدّم هذه المنظّمة
                    خدمات الصحة النفسية في هذه البيوت إذ أنها "ليس عيادة". ويشرح
                    مؤسس Small Scale Facility Amsterdam West، رود جايكوبز، أنّ
                    المنظّمة تتبع "مقارية وليس طريقة" وشرح أنّ "التواجد لأجل
                    الفرد" والتفاعل مع كافة الأمور بطريقة طبيعية بسيطة" يحدث
                    تأثيراً كبيراً، بخاصةٍ مع الشباب الذين خاضوا تجربة "العيادة"
                    مع مقدّمي الخدمات الاجتماعية لفترة طويلة. فلدى هذه المنظّمة،
                    لا يعد ضباط الأمن "ضباطاً" بل "مضيفين" مسؤولون عن بناء
                    العلاقات الحميدة مع الجيران.
                  </li>
                  <li>
                    <span className="strong">الهيكلية حرجة: </span>
                    يعتبر العمل من الأسفل إلى الأعلى أفضل من العكس، وعلى
                    المنظّمات أن تبني برامجها مع المجتمعات وليس لأجلها. تفاعلت
                    جمعية JustReinvest في أستراليا مع مجتمع بلدتها، بخاصة كبار
                    السنّ في بلدة بورك، بتمكينهم لتحديد رؤيتهم عن الاستثمار
                    المجتمعي المرغوب، فلم يقترحوا برنامجاً جاهزاً يعاد صياغته
                    ليناسب مجتمعاً سئم منه. من جهته، تبع مركز Red Hook Community
                    Justice Center سياسة "الإصغاء أولا" عندما أصبح ثاني محكمة
                    مجتمعية في نيويورك، فسمج بأعضاء المجتمع باختيار المواضيع
                    المراد التركيز عليها وموقع المحكمة وطريقة العمل التي تتبعها.
                    لهذا السبب، يتميز كلّ برنامج ضمن مركز الابتكار في المحاكم –
                    ومنه مركز Red Hook Community Justice Center – بكونه فريداً
                    من نوعه، ترسم هيكليته حاجات المجتمع الذي يكون فيه.
                  </li>
                  <li>
                    <span className="strong">أهمية الانفتاح: </span>
                    كلّما كانت المنشأة أكثر انفتاحاً وسمحت للخروج في عطلة نهاية
                    الأسبوع، والخروج للعمل وللتعلم وغيرها، كلّما سمحت بالاندماج
                    في المجتمع، وذلك أساسي في جهود تحقيق العدالة. يجب أن يأخذ
                    المناصرون من حول العالم ذلك في الحسبان ويعملون من أجل تحرير
                    بعض القيود، سامحين للسجناء بالمشاركة في برامج في المجتمع،
                    بدلاً من تطوير البرامج داخل أماكن الاحتجاز.
                  </li>
                  <li>
                    <span className="strong">المبادئ التوجيهية: </span>
                    من بين عملاء المنظمات العالمي، تكررت الكلمات في سياق وصف
                    البرامج القوية: الثقة والتمكين والعائلة واحترام الرؤية طويلة
                    الأمل للتغيير والثقة والتغيير. تكرر أيضاً الحديث عن "الإحساس
                    بأنك إنسان" بفضل البرنامج القوي، كذلك الحديث عن المجتمع
                    وحيوية ديناميكيات المجموعات. تكررت الكلمات نفسها كصفات
                    لأعمال هذه المنظمات: العدالة، والمساءلة، والابتكار،
                    والاحترام، والكرامة والمجتمع (الرسم رقم 4).
                  </li>

                  <div>
                    <h3 style={{ textAlign: 'center' }}>الرسم رقم 4</h3>
                    <p style={{ textAlign: 'center' }}>
                      الصفات التي تمثّل المبادئ التوجيهية للمنظمات
                      <br />
                      <span style={{ fontStyle: 'italic' }}>
                        أذكر إلى حدّ خمس صفات تمثل المبادئ التوجيهية والقيم
                        الخاصة بمنظّمتكم.
                      </span>
                    </p>
                  </div>
                  <figure style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src="https://images.ctfassets.net/fhwlk5jsnns3/2QjBoGQA2EcVd8dzOCpWkV/2c72e8025545f2c7ba36dbc1048eaaf7/wordMap.png"
                      alt="Placeholder"
                      width="650"
                      height="650"
                    />
                  </figure>
                  <li>
                    <span className="strong">التغيير الفوري والشامل</span>
                    تكون البرامج المثيرة للاهتمام شاملة وشخصية في الوقت نفسه،
                    أيّ أنها قادرة على تقديم خدمات مباشرة للأفراد في الحاضر فيما
                    تناصر وتعمل على تغيير هيكلي شامل في المستقبل ينهي أعمالها.
                    تقوم البرامج بذلك بطرق مختلفة، فـبرنامج African Prisons
                    Project في كينيا وأوغندا، يؤمن شهادات في القانون، ونتجت
                    أعمالها على إلغاء عقوبة الإعدام في البلدين بفضل أعمال
                    المناصرة التي قام بها طلابها. كذلك، غيّروا ثقافة حرّاس السجن
                    بضمّهم إلى الطلاب السجناء في الصفوف، ما قد يؤدّي إلى تغيير
                    شامل على المستوى البعيد. تعمل منظمتي Juvenile Justice
                    Advocates International و Equis في المكسيك في عمل المناصرة
                    بشكل أساسي، لكنّها تقدّم خدمات مباشرة جانبية كذلك، تعرّف
                    المتطوعين إلى السجون والسجناء بالإضافة إلى تأمين الخدمات
                    للعملاء، مما يساهم في تغيير الثقافات. في فرنسا، تقوم منظّمة
                    Genepi بتشريك طلاب جامعيين من حول البلد لإدارة ورش العمل
                    داخل السجون، ما يؤمن خدمة للسجناء ويعرف الشباب إلى إنسانية
                    السجناء بانيين شغفاً لتحقيق العدالة لهم – وهذه طريقة أخرى
                    لتحقيق تغيير ثقافي واسع. أمّا المثير للاهتمام، فهو أنّ
                    ثمانية من البرامج المتعمق بها فقط أعربت عن "الرغبة بتفكيك
                    السجون" كرؤيتها بعيدة الأمد، فيما ذكرت أغلب البرامج "الحدّ
                    من اكتظاظ السجون" كرؤيتها (21 برنامجاً).
                  </li>

                  <li>
                    <span className="strong">قوّة الشعب: </span>
                    رغم "وصفات" العمل القوي، بيد أنّ تعريف العدالة يبقى بين أيدي
                    الأنظمة والهيكليات – والشعب هو من يعيّش الأنظمة. لذلك، يمكن
                    لشخص واحد أن يحدث فرقاً في نظام عدالة كامل – شهدت The
                    Incarceration Nations Network على ذلك في كوستاريكا وجمهورية
                    التشيك وإيطاليا وهولندا والمكسيك، حيث بنيت البدائل المبتكرة
                    للسجون التقليدية (في البلدان الأربعة الأولى) وأحدث تغييرات
                    كبيرة في الاحتجاز ما قبل المحاكمة (في المكسيك) لأنّ شخصاً
                    واحداً في منظّمة تسعى إلى تحقيق العدالة أصّر على تحقيق ذلك،
                    رغم جميع المعاكسات. تظهر نظرية التشبيك الحديثة التأثيرات
                    المتتالية للأعمال الفردية والأشخاص. ينطبق أيضاً مفهوم "من
                    يعمل وليس ما الذي يعمل" عندما ننظر إلى مكونات البرامج
                    القوية؛ فقد ركّز شركاء The Incarceration Nations Network
                    مراراً وتكراراً على أهميّة فريق العمل القوي، بخاصةٍ في Small
                    Scale Facility Amsterdam West، حيث كان ستة من موظفيهم السبعة
                    من المغرب أو من سورينام، أي يتشاركون الخلفيات الثقافية
                    والمجتمعية مع عملائهم.
                  </li>
                  <li>
                    <span className="strong">
                      بناء القادة من أصحاب التجارب والخبرات
                    </span>
                    يكون الاستثمار بالتغيير الهيكلي الشامل بالاستثمار بالقادة في
                    العمل والمناصرة الاجتماعية، بالأخص القادة متنوعي الثقافات
                    وأصحاب التجارب والخبرات الذين يعلمون عن قرب بالمشاكل
                    والحلول، فيشكّلون بحراً من المعرفة ولا يعتبرون ما يصادفهم
                    مزيج من المشاكل. رغم أهمية توفر أصحاب التجارب والخبرات في
                    فرق عمل البرامج، لكن أقل من 15% من موظفي البرامج المتعمق بها
                    هم من المساجين أو المساجين السابقين. ومن بين جميع البرامج
                    المتعمق بها، 30% أعربت عن عمل سجين أو سجين سابق في فريق
                    عملها، فيما 12% كان لديها سجين أو سجين سابق ضمن مجلس
                    إدارتها. فيما تبين أن كثير من المنظمات لديها موظفين أو
                    متطوعين أقرباء لأشخاص محتجزين، تتمنى The Incarceration
                    Nations Network أن تتوجه هذه الجمعيات نحو ضمّهم ضمن فرق
                    عملها ومجالس إدارتها.
                    <br />
                    ولتسهيل هذه العملية، على الحكومات أن تكفّ عن بناء الحواجز
                    القانونية لـ"من هم أقرب إلى المشكلة"، حسبما تصفهم
                    JustLeadership USA ، لكي يصبحوا "أقرب إلى الحلّ". هناك
                    مجالات كاملة – كالعمل الاجتماعي في جنوب أفريقيا وكندا،
                    والمجال القانوني في كينيا مثلا – يمنع أن يعمل المدانون
                    سباقاً بها، رغم قدراتهم على تحسبن هذه المجالات ومجتمعاتهم.
                    وتبرز الحاجة إلى التقاضي الاستراتيجي في هذا المجال في بلدان
                    عدّة.
                  </li>
                  <li>
                    <span className="strong">
                      توسيع مفهوم أصحاب التجارب والخبرات
                    </span>
                    علينا توسيع تعريفنا عندما نتكلم عن أولئك الذين تأثروا بنظام
                    العدالة الجنائية: فهم ليسوا من ذهب إلى السجن فقط، بل
                    عائلاتهم أيضًا ، وأولئك الذين ينتمون إلى مجتمعات أعدادها
                    كثيفة في السجون وممن نجحوا في تجنب العدالة الجنائية، هذا
                    بالإضافة إلى الأحياء التي ينتمون إليها – الأحياء التي يدخل
                    كثير من سكانها في نظام العدالة الجنائية بشكل عام. وبينما
                    تتزايد الخدمات والاهتمام بعائلات المحتجزين على المستوى
                    العالمي، من أوروبا إلى سنغافورة إلى الولايات المتحدة، لكن
                    الخدمات المقدمة للأحياء / المجتمعات المذكورة أعلاه لا تزال
                    شحيحة. أحد الاستثناءات على ذلك هو مركز Red Hook Community
                    Justice Center في الولايات المتحدة، الذي يقدّم باقة من
                    الخدمات - القانونية والتعليمية والثقافية - لجميع أعضاء مجتمع
                    ريد هوك في بروكلين، وليس فقط أولئك الذين يواجهون مشاكل مع
                    القانون.
                  </li>

                  <li>
                    <span className="strong">تحدّي الصدمة النفسية: </span>
                    لا يمكن تأدية عملية لتحقيق العدالة في أيّ مكان من دون مواجهة
                    العائق الأكبر: الصدمة النفسيّة. يعاني السجناء بكثافة من هذه
                    الحالة، قبل السجون (نتيجة التمييز العنصري وعدم المساواة)،
                    خلال فترة السجن المجردة من الإنسانية، وبعد السجن في مواجهة
                    إعادة الاندماج في المجتمع والوصمة المرافقة لها. يشرح الطبيب
                    غابور ماتي، "إن جوهر الصدمة هو الانفصال عن أنفسنا"، والسجن
                    هو اقصى درجات الانفصال، في كثير من النواح. لذلك، يجب أن تكون
                    المقاربات في جميع التدخلات مراعية للصدمات. ويجب أن يتم
                    التركيز بشكل خاص على برامج إعادة التأهيل وإعادة الدخول؛ ومع
                    أنّ خدمات زيادة المهارات المهنية والتعليم والوظائف أساسيّة،
                    غير أن الجهود المبذولة في هذا الاتجاه يمكن أن تتعرض للتخريب
                    بسرعة عندما لا تتم معالجة الصدمة التي يتعرض لها أي شخص
                    والشفاء منها.
                  </li>
                  <li>
                    <span className="strong">الابتكار الجذري: </span>
                    بشكل عام، يستحق مجال العدالة، وهو ركيزة المجتمعات المستقرة،
                    الحريّة والتمويل لإجراء الاختبارات والابتكارات الجذرية. فكما
                    يتلقى وادي السيليكون الدعم لإجراء التجارب والاختبارات
                    للتطبيقات والتكنولوجيا بحرية حتّى يصلون إلى نموذج فعال، إلا
                    يحقّ للساعين إلى تحقيق العدالة بالدعم والحرية نفسها؟ نستحقّ
                    جميعنا عالماً حيث يقدّر ويحتضن التفكير الخلاّق الجذري، من
                    أجل المصلحة العامة.
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default BlueprintArTemplate;
