import React from 'react';

import BlueprintLayout from '../components/ui/Layout/BlueprintLayout';

import { BLUEPRINT_AR_MENU } from '../utils/constants';

import BlueprintArTemplate from '../templates/BlueprintArTemplate';

const BlueprintFr = () => {
  return (
    <BlueprintLayout menuItems={BLUEPRINT_AR_MENU}>
      <BlueprintArTemplate />
    </BlueprintLayout>
  );
};

export default BlueprintFr;
